export default {
  "dataIterator": {
    "rowsPerPageText": "Einträge pro Seite:",
    "rowsPerPageAll": "Alle",
    "pageText": "{0}-{1} von {2}",
    "noResultsText": "Keine Einträge gefunden.",
    "nextPage": "Nächste Seite",
    "prevPage": "Vorherige Seite"
  },
  "dataTable": {
    "rowsPerPageText": "Einträge pro Seite:"
  },
  "noDataText": "Keine Daten vorhanden"
}
