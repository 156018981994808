export default {
  methods: {
    keepQuery(to) {
      const { route } = this.$router.resolve(to, this.$route)
      const from = this.$route
      if (route.meta.keepQuery && Object.keys(from.query).length) {
        const query = {}
        if (to.query) {
          Object.assign(query, to.query)
        }
        route.meta.keepQuery.forEach(q => {
          if (!query.hasOwnProperty(q) && from.query.hasOwnProperty(q)) {
            query[q] = from.query[q]
          }
        })
        const newRoute = Object.assign({}, to)
        newRoute.query = query
        return newRoute
      } else {
        return to
      }
    }
  }
}
