function hasNodeError(node) {
  if (node) {
    if (node.eRrOrs.length) {
      return true
    }
    return Object.entries(node).some(([name, value]) => {
      if (name !== 'eRrOrs') {
        return hasNodeError(value)
      }
    })
  } else {
    return false
  }
}

function allNodeErrors(node, errors) {
  if (node) {
    errors.push(...node.eRrOrs)
    Object.entries(node).forEach(([name, value]) => {
      if (name !== 'eRrOrs') {
        allNodeErrors(value, errors)
      }
    })
  }
}

export default {
  data() {
    return {
      graphqlErrorsObject: {}
    }
  },
  methods: {
    gqlAddSingleError(message, ...path) {
      this.gqlAddErrors({ graphQLErrors: [{ message: message || '', path }] }, true)
    },
    gqlAddErrors(error, keepOldErrors = false, networkErrorPath = ['network']) {
      if (error.networkError) {
        this.gqlAddSingleError('Ein Unerwarteter Fehler ist aufgetreten Bitte versuchen Sie es später nocheinmal.', ...networkErrorPath)
      }
      if (!error.graphQLErrors) {
        return []
      }

      if (!keepOldErrors) {
        error.graphQLErrors.forEach(err => {
          let root = err.path && err.path[0]
          if (root && this.graphqlErrorsObject[root]) {
            this.graphqlErrorsObject[root] = null
          }
        })
      }

      let globalErrors = []
      error.graphQLErrors.forEach(err => {
        let node = this.graphqlErrorsObject
        if (err.path && err.path.length) {
          err.path.forEach(path => {
            if (!node[path]) {
              this.$set(node, path, {'eRrOrs': []})
            }
            node = node[path]
          })
          node.eRrOrs.push(err.message)
        } else {
          globalErrors.push(err)
        }
      })

      return globalErrors
    },
    gqlClearErrorsFor(...roots) {
      roots.forEach(root => {
        if (this.graphqlErrorsObject[root]) {
          this.graphqlErrorsObject[root] = null
        }
      })
    },
    gqlClearErrorsForSingle(...path) {
      let node = this.graphqlErrorsObject
      path.every(elem => {
        node = node[elem]
        return node
      })
      if (node) {
        node.eRrOrs = []
      }
    },
    gqlClearAllErrors() {
      Object.keys(this.graphqlErrorsObject).forEach(root => {
        if (this.graphqlErrorsObject[root]) {
          this.graphqlErrorsObject[root] = null
        }
      })
    },
    gqlHasErrorsFor(...path) {
      let node = this.graphqlErrorsObject
      path.every(elem => {
        node = node[elem]
        return node
      })
      return hasNodeError(node)
    },
    gqlErrorsFor(...path) {
      let node = this.graphqlErrorsObject
      path.every(elem => {
        node = node[elem]
        return node
      })
      return (node && node.eRrOrs) || []
    },
    gqlAllErrorsFor(...path) {
      const node = this.gqlErrorForPath(path)
      const errors = []
      allNodeErrors(node, errors)
      return errors
    },
    gqlErrorForPath(path) {
      let node = this.graphqlErrorsObject
      path.every(elem => {
        node = node[elem]
        return node
      })
      return node
    },
    // options: loading, mutationName, successMsg, errorMsg, variables, mutation, then, catch, finally, noBaseError
    gqlAction(options) {
      if (options.loading) {
        this[options.loading]++
      }
      if (options.mutationName) {
        this.gqlClearErrorsFor(options.mutationName)
      }
      let mutate = this.$apollo.mutate({
        mutation: options.mutation,
        variables: options.variables,
      })
      if (options.then || options.catch) {
        const thenFn = options.then ? (r) => {
          const resp = options.then.call(this, r)
          if (resp === undefined) {
            return r
          } else {
            return resp
          }
        } : null
        const catchFn = options.catch ? (e) => {
          const resp = options.catch.call(this, e)
          if (resp === undefined) {
            throw e
          } else {
            return resp
          }
        } : null
        mutate = mutate.then(thenFn, catchFn)
      }
      mutate = mutate.then(response => {
        if (options.loading) {
          this[options.loading]--
        }
        if (options.successMsg) {
          let successMsg = options.successMsg
          if (typeof options.successMsg === 'function') {
            successMsg = options.successMsg(response && response.data)
          }
          if (successMsg) {
            this.$store.commit('addSuccess', { text: successMsg, timeout: 6000 })
          }
        }
        return response
      }, err => {
        if (options.loading) {
          this[options.loading]--
        }
        this.gqlAddErrors(err)
        let baseError = ''
        if (!this.noBaseError && options.mutationName) {
          baseError = this.gqlErrorsFor(options.mutationName, 'base').join(' ')
          if (baseError && !options.errorMsg) {
            this.$store.commit('addError', { text: baseError, timeout: 1200*10 })
          }
        }
        if (options.errorMsg) {
          let errorMsg = baseError || options.errorMsg
          if (typeof options.errorMsg === 'function') {
            errorMsg = options.errorMsg(err, baseError || null)
          }
          if (errorMsg) {
            this.$store.commit('addError', { text: errorMsg, timeout: 1200*10 })
          }
        }
        throw err
      })
      if (options.finally) {
        const finallyFn = (r) => options.finally.call(this, r)
        mutate = mutate.then(finallyFn, finallyFn)
      }
      return mutate
    },
  }
}
