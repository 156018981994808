<template lang="pug">
v-radio-group.radio-group__wrapper.mt-0(:label="label" :value="value" @change="$emit('change', $event)" :mandatory="mandatory" :hide-details="hideDetails" :row="row" :error-messages="errorMessages" :disabled="disabled" :style="style")
  v-radio(v-for="opt in itemFoReal" :key="opt[itemValue]" :label="opt[itemText]" :value="opt[itemValue]" color="primary")

</template>

<style lang="stylus" scoped>
.radio-group__wrapper
  margin-bottom -8px
  >>>
    .v-input__slot
      margin-bottom 0
    .v-input--radio-group__input > .v-label
      font-size 12px
      font-weight 400
      width 100%
      padding-bottom 0

</style>

<script>
export default {
  name: 'radio-group',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: true,
    label: String,
    hideDetails: Boolean,
    row: Boolean,
    jesNo: Boolean,
    disabled: Boolean,
    mandatory: {
      type: Boolean,
      default() { return true }
    },
    items: {
      type: Array,
      default() {
        return [{ text: 'Alle', value: null }, { text: 'Ja', value: true }, { text: 'Nein', value: false }]
      }
    },
    itemText: {
      type: String,
      default: "text"
    },
    itemValue: {
      type: String,
      default: "value"
    },
    errorMessages: {
      type: Array,
      default() { return [] }
    }
  },
  computed: {
    style () {
        return (this.row && this.hideDetails) ? 'border-bottom: 1px solid rgba(0,0,0,0.42)' : ''      
    },
    itemFoReal() {
      if (this.jesNo) {
        return [{ text: 'Ja', value: true }, { text: 'Nein', value: false }]
      } else {
        return this.items
      }
    }
  }
}
</script>
