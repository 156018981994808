import { render, staticRenderFns } from "./file-input.vue?vue&type=template&id=a924eb56&scoped=true&lang=pug&"
import script from "./file-input.vue?vue&type=script&lang=js&"
export * from "./file-input.vue?vue&type=script&lang=js&"
import style0 from "./file-input.vue?vue&type=style&index=0&id=a924eb56&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a924eb56",
  null
  
)

export default component.exports