<template lang="pug">
  v-autocomplete(:label="label || 'Schule'" no-data-text="Keine Schulen" :hint="schule && `${schule.region} ${schule.bezirkLandkreis} ${schule.schulart}`" persistent-hint :value="value" :clearable="clearable" :disabled="disabled" @input="valueChanged" :items="items" :loading="$apollo.queries.schulen.loading" :hide-details="hideDetails" item-text="name" item-value="id" :error-messages="errorMessages || []")
    template(slot="item", slot-scope="data")
      template(v-if="typeof data.item !== 'object'")
        v-list-tile-content(v-text="data.item")
      template(v-else)
        v-list-tile-content
          v-list-tile-title
            | {{data.item.name}}
          v-list-tile-sub-title {{data.item.region}} {{data.item.bezirkLandkreis}} {{data.item.schulart}}

</template>

<script>
import gql from 'gql-id.macro'

export default {
  name: 'select-schule',
  props: {
    label: String,
    value: String,
    ort: String,
    clearable: Boolean,
    disabled: Boolean,
    other: Boolean,
    none: Boolean,
    'hide-details': Boolean,
    'error-messages': Array
  },
  data() {
    return {
      schulen: []
    }
  },
  apollo: {
    schulen: gql`query selectSchuleVue {
      schulen(order: "name", role: ANON) {
        id
        name
        region
        bezirkLandkreis
        schulart
      }
    }`
  },
  computed: {
    schule() {
      const value = this.value
      const schule = this.schulen.find(schule => schule.id === value)
      this.$emit('input-data', schule)
      return schule
    },
    items() {
      if (this.other || this.none) {
        const opts = []
        if (this.none) {
          opts.push({ id: '00', name: 'Besucht Keine Schule', region: '', bezirkLandkreis: '', schulart: '' })
        }
        if (this.other) {
          opts.push({ id: '0', name: 'Sonstige / Andere Schule', region: '', bezirkLandkreis: '', schulart: '' })
        }
        return opts.concat(this.schulen)
      } else {
        return this.schulen
      }
    }
  },
  methods: {
    valueChanged(val) {
      this.$emit('input', val)
    }
  }
}
</script>
