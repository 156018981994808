export default {
  data() {
    return {
      queryDefaultPer: 25,
      queryDefaultOrder: null,
      queryDefaultDescending: true,
      queryDefaults: {}
    }
  },
  computed: {
    queryFilterPagination() {
      const query = this.$route.query
      return {
        sortBy: query.hasOwnProperty('order') ? query.order : this.queryDefaultOrder,
        descending: this.queryDefaultDescending ? query.asc !== '1' : query.desc === '1',
        page: query.page - 0 || 1,
        rowsPerPage: query.hasOwnProperty('per') ? query.per === 'alle' ? -1 : query.per - 0 : this.queryDefaultPer,
      }
    }
  },
  methods: {
    // use when value has a default. otherwise $route.query.name can be used
    queryFilterValue(name) {
      const query = this.$route.query
      return query.hasOwnProperty(name) ? query[name] : this.queryDefaults[name]
    },
    queryFilterValueBool(name) {
      const val = this.queryFilterValue(name)
      if (val === true || val === 'true') {
        return true
      }
      if (val === false || val === 'false') {
        return false
      }
      return null
    },
    queryFilterClear() {
      this.queryFilterPaginationUpdate(this.queryFilterPagination, {})
    },
    queryFilterPaginationUpdate(pagination, oldQuery = this.$route.query) {
      const query = {}
      Object.assign(query, oldQuery)
      if (this.queryDefaultDescending) {
        delete query.desc
        if (pagination.descending) {
          delete query.asc
        } else {
          query.asc = '1'
        }
      } else {
        delete query.asc
        if (pagination.descending) {
          query.desc = '1'
        } else {
          delete query.desc
        }
      }
      if (pagination.page > 1) {
        query.page = pagination.page
      } else {
        delete query.page
      }
      if (this.queryDefaultPer !== pagination.rowsPerPage) {
        query.per = pagination.rowsPerPage < 0 ? 'alle' : pagination.rowsPerPage
      } else {
        delete query.per
      }
      if (pagination.sortBy !== this.queryDefaultOrder && pagination.sortBy !== null) {
        query.order = pagination.sortBy
      } else {
        delete query.order
      }
      Object.entries(this.queryDefaults).forEach(([k,v]) => {
        if (query[k] === v) {
          delete query[k]
        }
      })
      this.$router.replace({ query })
    },
    queryFilterUpdate(attr, value, scrollTo = null, options = { }) {
      const values = {}
      values[attr] = value
      this.queryFilterUpdates(values, scrollTo, options)
    },
    queryFilterUpdates(attrs, scrollTo = null, options = { }) {
      const query = {}
      Object.assign(query, this.$route.query)
      Object.entries(attrs).forEach(([attr, value]) => {
        if (this.queryDefaults.hasOwnProperty(attr) ? value !== this.queryDefaults[attr] : value || value === false) {
          query[attr] = value === undefined ? null : value
        } else {
          delete query[attr]
        }
      })
      if (!options.keepPage) {
        delete query.page
      }
      const hash = options.hash
      if (options.push) {
        this.$router.push({ query, params: { scrollTo }, hash })
      } else {
        this.$router.replace({ query, params: { scrollTo }, hash })
      }
    }
  }
}
